import React from 'react';
import { Global } from '@theme';
import { Layout } from '@components';
import { SEO } from '@components';
import {
  InitialSection,
  ContactSection,
  SolutionsSection,
  StepsSection,
  TechnologiesSection,
  DifferentialsSection,
  CasesSection,
} from '@screens/Main';

const TitlePage3 = () => (
  <>
    <Global />
    <Layout>
      <SEO title="Outsourcing em desenvolvimento" />
      <InitialSection text="Precisa de apoio para a criação de aplicativo / criação de app ? Vamos te dar help para tirar sua ideia do papel!"/>
      <SolutionsSection />
      <StepsSection />
      <TechnologiesSection />
      <CasesSection />
      <DifferentialsSection />
      <ContactSection />
    </Layout>
  </>
);

export default TitlePage3;
